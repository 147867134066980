import * as React from 'react';
import {useState} from 'react';
import {getErrorIfExists} from "./CommonSubComponents";
import {Navigate} from "react-router-dom";
import SubscriptionPlans from "./SubscriptionPlans";
import {urls} from "../urls";
import {useAppSelector} from "../store";

export default () => {
    const [paymentError, _] = useState<string | undefined>(undefined)
    const signedIn = useAppSelector(a => a.account.signedIn)
    if (signedIn) {
        return <div>
            {getErrorIfExists(paymentError)}
            <div className="text-center">
                <h1>Choose your plan and continue learning now</h1>
            </div>
            <SubscriptionPlans/>
        </div>
    } else {
        return <Navigate to={urls.account.signin}/>
    }
}