import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {SubscriptionPlan} from "../helpers/payment";
import {getJson} from "../helpers/web";
import {urls} from "../urls";
import {UserProfileViewModel} from "../helpers/user";
import {Result, WebRequestResult} from "../viewModels/WebResult";

const userProfileLocalStorageKey = 'userProfile';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

interface AccountState {
    expiryDate: string | undefined;
    signedIn: boolean;
    email: string | undefined
    subscribed: boolean;
    emailConfirmed: boolean;
    desiredSubscriptionPlan: SubscriptionPlan | undefined;
    freeTrial: boolean;
    characterTypePreference: 'Simplified' | 'Traditional';
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface SetEmail {
    email: string
}

interface SetDesiredSubscriptionPlan {
    plan: SubscriptionPlan
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
function getInitialState(): AccountState {
    const userProfile = getUserProfile();

    return {
        expiryDate: userProfile.subscribed ? userProfile.subscriptionEndsDate : undefined,
        signedIn: userProfile.loggedIn,
        email: undefined,
        emailConfirmed: userProfile.emailConfirmed,
        subscribed: userProfile.subscribed,
        desiredSubscriptionPlan: undefined,
        freeTrial: userProfile.freeTrial,
        characterTypePreference: userProfile.characterTypePreference
    };
}


const accountSlice = createSlice({
    name: "account",
    initialState: getInitialState(),
    reducers: {
        signOutUser: (_) => {
            logoutUser();
            return getInitialState();
        },
        setUserProfile: (state, action: PayloadAction<{ json: string }>) => {
            persistUserProfileFromJson(action.payload.json);
            return getInitialState();
        },
        setDesiredSubscriptionPlan: (state, action: PayloadAction<SetDesiredSubscriptionPlan>) => {
            state.desiredSubscriptionPlan = action.payload.plan;
        },
        setEmail: (state, action: PayloadAction<SetEmail>) => {
            state.email = action.payload.email;
        },
        setEmailAsConfirmed: (state) => {
            state.emailConfirmed = true
        },
    },
    extraReducers: (builder) => {
        builder.addCase(refreshUserProfileAsync.fulfilled, (state, action) => {
            const response: Result<WebRequestResult> = JSON.parse(action.payload); 
            switch (response.type) {
                case "failure":
                    persistUserProfile(getDefaultUserProfile())
                    return getInitialState();
                case "success":
                    persistUserProfileFromJson(response.data.successData);
                    return getInitialState();
            }
        })
    }
});

function getDefaultUserProfile(): UserProfileViewModel {
    return {
        loggedIn: false,
        emailConfirmed: false,
        subscribed: false,
        subscriptionEndsDate: Date.UTC(1, 1, 1).toString(),
        freeTrial: false,
        characterTypePreference: 'Simplified'
    }
}

function persistUserProfile(userProfileViewModel: UserProfileViewModel) {
    persistUserProfileFromJson(JSON.stringify(userProfileViewModel));
}

function persistUserProfileFromJson(json: string) {
    localStorage.setItem(userProfileLocalStorageKey, json)
}

function getUserProfile(): UserProfileViewModel {
    const userProfileJSON = localStorage.getItem(userProfileLocalStorageKey);

    if (userProfileJSON) {
        const userProfile = JSON.parse(userProfileJSON);
        return {...userProfile, characterTypePreference: userProfile.characterTypePreference ?? 'Simplified'}
    }
    return {
        loggedIn: false,
        emailConfirmed: false,
        subscribed: false,
        subscriptionEndsDate: new Date(0, 0, 0).toDateString(),
        freeTrial: false,
        characterTypePreference: 'Simplified'
    }
}

function logoutUser() {
    localStorage.removeItem(userProfileLocalStorageKey);
}

export const refreshUserProfileAsync = createAsyncThunk(
    'account/refreshUserProfile',
    async () => JSON.stringify(await getJson(urls.api.account.profile)));

export const { setEmailAsConfirmed, setEmail, setUserProfile, signOutUser, setDesiredSubscriptionPlan } = accountSlice.actions;

export default accountSlice.reducer