import * as React from "react";
import {Navigate} from "react-router-dom";
import {urls} from "../urls";

export const getRequiredEmailForm = (email: React.RefObject<HTMLInputElement>) => {
    return <>
        <label htmlFor="email">Email</label>
        <input
            name="email"
            placeholder="wang@example.com"
            type="text"
            ref={email}
            required={true}
            className="form-control"/>
    </>;
};

export const getRequiredPassword = (password: React.RefObject<HTMLInputElement>) => {
    return <>
        <label htmlFor="password">Password</label>
        <input
            name="password"
            placeholder="Enter your password"
            type="password"
            ref={password}
            required={true}
            className="form-control"/>
    </>;
};


export const LoaderOverlay = ({loading, children}: React.PropsWithChildren<{ loading: boolean; }>) => {
    return <>
    {loading ? <div className="spinner-overlay spinner-overlay-background">
            <div className="spinner-border text-primary"/>
        </div>
        : null}
        {children}
    </>
};

export const getErrorIfExists = (error: string | undefined) => {
    return error ? <div className="alert alert-danger alert-dismissible">{error}</div> : null;
};

export const getSuccessMessageIfExists = (success: string | undefined) => {
    return success ? <div className="alert alert-success alert-dismissible">{success}</div> : null;
};

export const getRedirectToEmailConfirmation = () => {
    return <Navigate to={urls.account.confirm}/>
};

export const bigBadge = (
    badgeColor: 'bg-success' | 'bg-primary' | 'bg-warning' | 'bg-secondary' | 'bg-danger',
    text: number | string) => {
    return <div className={`badge ${badgeColor} fs-1-2rem w-100 pb-2`}>{text}</div>
};

export const baseCampPolicyAttribution = () => {
    return <p>
        <em>
            Adapted from the <a href="https://github.com/basecamp/policies">Basecamp open-source policies / CC BY 4.0</a>
        </em>
    </p>
}