import React from "react"
import {getPinyinColors, getPinyinSyllables} from "../helpers/utils";

export default ({pinyin} : {pinyin: string}) => {
    const colors = getPinyinColors(pinyin);
    const syllables = getPinyinSyllables(pinyin);

    return <>
        {syllables.map((syllable, i) => <span key={i} style={{color: colors[i]}}>
            {syllable}
        </span>)}
    </>
}