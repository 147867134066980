import React, {useEffect, useRef, useState} from "react";
import {WebRequestResult} from "../viewModels/WebResult";
import {postJson} from "../helpers/web";
import {urls} from "../urls";

export interface CredentialResponse {
    credential: string;
    select_by: string;
    clientId: string;
}

export default function (props: { handleResponse: (webRequestResult: WebRequestResult) => void }) {
    const googleButtonRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const handle = setInterval(() => {
            const google = (window as any).google;
            if (google && googleButtonRef.current) {
                google.accounts.id.initialize({
                    client_id: "415577552087-65c5s17t97e6u13f130fot60k9p6u7c5.apps.googleusercontent.com",
                    callback: async (credentialResponse: CredentialResponse) => {
                        const response = await postJson(urls.api.account.socialLogin, {
                            userId: credentialResponse.clientId,
                            accessToken: credentialResponse.credential,
                            provider: 'Google'
                        });

                        props.handleResponse(response.webRequestResult);
                    }
                });

                google.accounts.id.renderButton(googleButtonRef.current, {
                    size: 'large',
                    width: googleButtonRef.current.clientWidth,
                })
                clearInterval(handle)
            }

        }, 300)
    }, [googleButtonRef])

    return <div className="d-block" ref={googleButtonRef}/>
}