import * as React from 'react';
import {ReactNode, useCallback, useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {MinimalLayout, StandardLayout} from './components/Layout';
import Home from './components/Home';

import './styles/main.scss'
import SubscriptionSelection from "./components/SubscriptionSelection";
import {SignInForm} from "./components/SignInForm";
import {RegistrationForm} from "./components/RegistrationForm";
import {EmailConfirmationForm} from "./components/EmailConfirmationForm";

import {urls} from "./urls";
import {SubscriptionSuccess} from "./components/SubscriptionSuccess";
import About from "./components/About";
import {refreshUserProfileAsync, useAppDispatch, useAppSelector} from "./store";

const Dashboard = React.lazy(() => import('./components/DashboardComponent'));
const Dictionary = React.lazy(() => import('./components/Dictionary'));
const LessonEditor = React.lazy(() => import('./components/LessonEditor'));
const Lesson = React.lazy(() => import('./components/Lesson'));
const Privacy = React.lazy(() => import('./components/PrivacyComponent'));
const TermsOfService = React.lazy(() => import('./components/TermsOfService'));
const Subscription = React.lazy(() => import('./components/Subscription'));
const NotFound = React.lazy(() => import('./components/NotFound'));
const RequestResetForm = React.lazy(() => import('./components/RequestResetForm'))
const ResetPasswordForm = React.lazy(() => import('./components/ResetPasswordForm'));
const ArticleDesigner = React.lazy(() => import('./components/ArticleDesigner'))
const Article = React.lazy(() => import('./components/Article'))

const RedirectToDashboard = () => <Navigate to={urls.dashboard}/>;
const RedirectToRegister = () => <Navigate to={urls.account.register}/>;
const RedirectToSubscribe = () => <Navigate to={urls.account.subscribe}/>;
const RedirectToConfirmation = () => <Navigate to={urls.account.confirm}/>;

export default function App() {
    const {signedIn, emailConfirmed} = useAppSelector(a => a.account);
    const accountComplete = useAppSelector(a => a.account.signedIn && a.account.emailConfirmed);
    const redirectToAccountCompletion = useCallback(() => {
        if (!emailConfirmed && signedIn) {
            return <RedirectToConfirmation/>
        }


        return <RedirectToRegister/>
    }, [signedIn, emailConfirmed]);

    const redirectToSubscribe = useCallback(() => {
        if (signedIn && emailConfirmed) {
            return <RedirectToSubscribe/>
        }


        return redirectToAccountCompletion();
    }, [signedIn, emailConfirmed]);
    const dispatch = useAppDispatch();

    async function updateUserData() {
        dispatch(refreshUserProfileAsync())
    }

    useEffect(() => {
        updateUserData();
    }, [])
    return <Routes>
        <Route path={urls.home} element={<StandardLayout><RestrictedComponent allowed={!accountComplete} element={<Home/>} fallback={<RedirectToDashboard/>}/></StandardLayout>}/>
        <Route path={urls.dictionary} element={<StandardLayout><Dictionary/></StandardLayout>}/>
        <Route path='/quiz/:lessonId' element={<MinimalLayout><Lesson/></MinimalLayout>}/>
        <Route path={urls.privacy} element={<StandardLayout><Privacy/></StandardLayout>}/>
        <Route path={urls.terms} element={<StandardLayout><TermsOfService/></StandardLayout>}/>
        <Route path={urls.about} element={<StandardLayout><About/></StandardLayout>}/>
        <Route path={urls.account.requestReset} element={<StandardLayout><RestrictedComponent allowed={!signedIn} element={<RequestResetForm/>} fallback={<RedirectToDashboard/>}/></StandardLayout>}/>
        <Route path={urls.account.resetPassword} element={<StandardLayout><RestrictedComponent allowed={!signedIn} element={<ResetPasswordForm/>} fallback={<RedirectToDashboard/>}/></StandardLayout>}/>
        <Route path={urls.account.signin} element={<StandardLayout><RestrictedComponent allowed={!signedIn} element={<SignInForm/>} fallback={<RedirectToDashboard/>}/></StandardLayout>}/>
        <Route path={urls.account.register} element={<StandardLayout><RestrictedComponent allowed={!accountComplete} element={<RegistrationForm/>} fallback={<RedirectToDashboard/>}/></StandardLayout>}/>
        <Route path={`${urls.account.confirm}/:code?`} element={<StandardLayout><RestrictedComponent allowed={!emailConfirmed} element={<EmailConfirmationForm/>} fallback={<RedirectToDashboard/>}/></StandardLayout>}/>
        <Route path={urls.dashboard} element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<Dashboard/>} fallback={redirectToAccountCompletion()}/></StandardLayout>}/>
        <Route path={urls.account.subscription} element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<Subscription/>} fallback={redirectToAccountCompletion()}/></StandardLayout>}/>
        <Route path={urls.account.subscribe} element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<SubscriptionSelection/>} fallback={redirectToAccountCompletion()}/></StandardLayout>}/>
        <Route path='/subscription/success/:checkoutSessionId' element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<SubscriptionSuccess/>} fallback={redirectToAccountCompletion()}/></StandardLayout>}/>
        <Route path={urls.articles.create} element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<ArticleDesigner/>} fallback={redirectToSubscribe()}/></StandardLayout>}/>
        <Route path={urls.articles.edit.getEditUrl(":articleId")} element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<ArticleDesigner/>} fallback={redirectToSubscribe()}/></StandardLayout>}/>
        <Route path='/article/:articleId/:index?' element={<MinimalLayout><RestrictedComponent allowed={accountComplete} element={<Article/>} fallback={redirectToSubscribe()}/></MinimalLayout>}/>
        <Route path={urls.lesson.create} element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<LessonEditor/>} fallback={redirectToSubscribe()}/></StandardLayout>}/>
        <Route path={'/lesson/edit/:lessonId'} element={<StandardLayout><RestrictedComponent allowed={accountComplete} element={<LessonEditor/>} fallback={redirectToSubscribe()}/></StandardLayout>}/>
        <Route path="/*" element={<StandardLayout><NotFound/></StandardLayout>}/>
    </Routes>
}


function RestrictedComponent({
                                 allowed,
                                 element,
                                 fallback
                             }: {
    allowed: boolean,
    element: ReactNode | null,
    fallback: ReactNode | null
}) {
    return <>{allowed ? element : fallback}</>;
}
