import React, {useState} from "react";
import {RegistrationForm} from "./RegistrationForm";
import {urls} from "../urls";
import {Link} from "react-router-dom";
import HandwritingExampleComponent from "./HandwritingExampleComponent";
import Presentation from "./Presentation";
import {QuizType, WordType} from "../generated/graphql/graphql-zeus";
import ArticlePage from "./ArticlePage";

export default function () {
    const [handwritingCompleted, setHandwritingCompleted] = useState(false);
    const [presentationCompleted, setPresentationCompleted] = useState(false);

    return (<>
        <div className="card mb-5 p-3">
            <div className="card-header text-center">
                <h1 className="anton">Features</h1>
                <hr/>
                <div className="text-center row">
                    <h4 className="card-title pricing-card-title p-3 col-6 col-sm-4">Progress <br/> Tracking</h4>
                    <h4 className="card-title pricing-card-title p-3 col-6 col-sm-4">100,000+ <br/> audio tracks</h4>
                    <h4 className="card-title pricing-card-title p-3 col-6 col-sm-4">Handwriting <br/> Practice Tool</h4>
                    <h4 className="card-title pricing-card-title p-3 col-6 col-sm-4">Stroke Order <br/> Animations</h4>
                    <h4 className="card-title pricing-card-title p-3 col-6 col-sm-4">Powerful <br/> Lesson Editor</h4>
                    <h4 className="card-title pricing-card-title p-3 col-6 col-sm-4">114727 <br/> Dictionary Entries</h4>
                </div>
                <div className="text-center text-muted fs-1-5rem mb-2">
                    <em>and more coming soon...</em>
                </div>
                <div className="text-center">
                    <Link to={urls.account.register} className="btn btn-primary btn-huge anton fs-1-5rem">Start
                        learning now</Link>
                </div>
            </div>
        </div>
        {
            handwritingCompleted
                ? <RegistrationForm/>
                : <HandwritingExampleComponent onCompleted={() => setHandwritingCompleted(true)}/>
        }
        <div className="card mb-5 p-3">
            <div className="card-header text-center">
                <h1 className="anton">Learn Chinese Now</h1>
                <hr/>
            </div>
            <div className="card-body pt-0 text-center">
                <div>
                    <p className="card-text fs-1-5rem font-weight-bold">
                        Learn now with our 7 day free trial
                    </p>
                    <p className="card-text fs-1-5rem ">
                        <em>No payment details are required to get started</em>
                    </p>
                    <p className="card-text fs-1-5rem font-weight-bold">
                        Why wait?
                    </p>
                </div>
                <div className="text-center mt-4">
                    <Link to={urls.account.register} className="btn btn-success btn-huge anton fs-1-5rem">Register now</Link>
                </div>
            </div>
        </div>
        <div>
            {
                presentationCompleted
                    ? <RegistrationForm/>
                    : <div className="card mb-5 p-3 box-shadow">
                        <Presentation
                            word={{
                                chineseWordId: '1234',
                                progress: 0,
                                quizType: QuizType.PRESENTATION,
                                pinyin: 'ye3',
                                index: 0,
                                chinese: '也',
                                englishTranslations: [{english: 'also', id: '', importance: 10}],
                                preferredTranslations: [],
                                strokeCount: 3
                            }}
                            autoFocus={false}
                            onCompleted={() => setPresentationCompleted(true)}
                            title="Learn new words!"/>
                    </div>
            }
        </div>
        <div className="card mb-5 p-3">
            <div className="card-header text-center">
                <h1 className="anton">Read Chinese Texts</h1>
                <hr/>
            </div>
            <div className="card-body">
                <div className="text-center mb-3">
                    <em>Click on words to see translations!</em>
                </div>
                <ArticlePage
                    page={{
                        pageSize: 500,
                        segments: [{type: WordType.IDIOM, word: "你好"}, {type: WordType.NON_MORPHEME, word: "！"}, {type: WordType.NON_MORPHEME, word: "\n"}, {
                            type: WordType.NON_MORPHEME,
                            word: "\n"
                        }, {type: WordType.VERB, word: "欢迎"}, {type: WordType.VERB, word: "学习"}, {type: WordType.OTHER_PROPER_NOUN, word: "中文"}, {
                            type: WordType.NON_MORPHEME,
                            word: "！"
                        }, {type: WordType.NON_MORPHEME, word: "\n"}, {type: WordType.NON_MORPHEME, word: "\n"}, {type: WordType.OTHER_PROPER_NOUN, word: "中文"}, {
                            type: WordType.VERB,
                            word: "是"
                        }, {type: WordType.NUMERAL, word: "一门"}, {type: WordType.ADVERB, word: "非常"}, {type: WordType.ADJECTIVE, word: "有趣"}, {
                            type: WordType.PARTICLE_4,
                            word: "的"
                        }, {type: WordType.NOUN, word: "语言"}, {type: WordType.NON_MORPHEME, word: "。"}, {type: WordType.NON_MORPHEME, word: "\n"}, {
                            type: WordType.NON_MORPHEME,
                            word: "\n"
                        }, {type: WordType.PRONOUN, word: "我"}, {type: WordType.VERB, word: "叫"}, {type: WordType.ENGLISH, word: "Mandarin"}, {
                            type: WordType.NOUN,
                            word: "先生"
                        }, {type: WordType.NON_MORPHEME, word: "。"}, {type: WordType.PRONOUN, word: "我"}, {type: WordType.CONJUNCTION, word: "可以"}, {
                            type: WordType.PREPOSITION,
                            word: "当"
                        }, {type: WordType.PRONOUN, word: "你"}, {type: WordType.PARTICLE_4, word: "的"}, {type: WordType.OTHER_PROPER_NOUN, word: "中文"}, {
                            type: WordType.NOUN,
                            word: "老师"
                        }, {type: WordType.NON_MORPHEME, word: "，"}, {type: WordType.VERB, word: "教"}, {type: WordType.PRONOUN, word: "你"}, {
                            type: WordType.OTHER_PROPER_NOUN,
                            word: "汉字"
                        }, {type: WordType.CONJUNCTION, word: "和"}, {type: WordType.NOUN, word: "拼音"}, {type: WordType.NON_MORPHEME, word: "。"}, {
                            type: WordType.NON_MORPHEME,
                            word: "\n"
                        }, {type: WordType.NON_MORPHEME, word: "\n"}, {type: WordType.ADVERB, word: "快来"}, {type: WordType.CONJUNCTION, word: "和"}, {
                            type: WordType.PRONOUN,
                            word: "我"
                        }, {type: WordType.NUMERAL, word: "一起"}, {type: WordType.NOUN, word: "学"}, {type: WordType.OTHER_PROPER_NOUN, word: "中文"}, {
                            type: WordType.MODAL,
                            word: "吧"
                        }, {type: WordType.NON_MORPHEME, word: "！"}],
                        chineseWords: [
                            {
                                englishTranslations: [{english: "Hello!"}, {english: "Hi!"}, {english: "How are you?"}, {english: "m55n58"}],
                                simplifiedChinese: "你好",
                                traditionalChinese: "你好",
                                pinyin: "ni3 hao3",
                                importance: 7.6961
                            },
                            {
                                englishTranslations: [{english: "one"}, {english: "a"}, {english: "an"}, {english: "single"}],
                                simplifiedChinese: "一门",
                                traditionalChinese: "一门",
                                pinyin: "yi1 men2",
                                importance: 10
                            },
                            {
                                englishTranslations: [{english: "quickly"}],
                                simplifiedChinese: "快来",
                                traditionalChinese: "快来",
                                pinyin: "kuai4 lai2",
                                importance: 10
                            }
                            , {
                                englishTranslations: [{english: "to welcome"}, {english: "welcome"}],
                                simplifiedChinese: "欢迎",
                                traditionalChinese: "欢迎",
                                pinyin: "huan1 ying2",
                                importance: 7.1051
                            }, {
                                englishTranslations: [{english: "to learn"}, {english: "to study"}],
                                simplifiedChinese: "学习",
                                traditionalChinese: "学习",
                                pinyin: "xue2 xi2",
                                importance: 8.086
                            }, {
                                englishTranslations: [{english: "Chinese"}, {english: "Chinese written language"}, {english: "Chinese writing"}],
                                simplifiedChinese: "中文",
                                traditionalChinese: "中文",
                                pinyin: "Zhong1 wen2",
                                importance: 8.101
                            }, {
                                englishTranslations: [{english: "is"}, {english: "are"}, {english: "am"}, {english: "yes"}, {english: "to be"}],
                                simplifiedChinese: "是",
                                traditionalChinese: "是",
                                pinyin: "shi4",
                                importance: 9.539
                            }, {
                                englishTranslations: [{english: "variant of 是[shi4]"}, {english: "(used in given names)"}],
                                simplifiedChinese: "是",
                                traditionalChinese: "是",
                                pinyin: "shi4",
                                importance: 2.327
                            }, {
                                englishTranslations: [{english: "very"}, {english: "very much"}, {english: "unusual"}, {english: "extraordinary"}],
                                simplifiedChinese: "非常",
                                traditionalChinese: "非常",
                                pinyin: "fei1 chang2",
                                importance: 7.8022
                            }, {
                                englishTranslations: [{english: "interesting"}, {english: "fascinating"}, {english: "amusing"}],
                                simplifiedChinese: "有趣",
                                traditionalChinese: "有趣",
                                pinyin: "you3 qu4",
                                importance: 6.4338
                            }, {
                                englishTranslations: [{english: "see 的士[di1 shi4]"}],
                                simplifiedChinese: "的",
                                traditionalChinese: "的",
                                pinyin: "di1",
                                importance: 1.1685
                            }, {
                                englishTranslations: [{english: "really and truly"}],
                                simplifiedChinese: "的",
                                traditionalChinese: "的",
                                pinyin: "di2",
                                importance: 7.0372
                            }, {
                                englishTranslations: [{english: "aim"}, {english: "clear"}],
                                simplifiedChinese: "的",
                                traditionalChinese: "的",
                                pinyin: "di4",
                                importance: 8.2093
                            }, {
                                englishTranslations: [{english: "of"}, {english: "'s (possessive particle)"}, {english: "(used after an attribute)"}, {english: "(used to form a nominal expression)"}, {english: "(used at the end of a declarative sentence for emphasis)"}],
                                simplifiedChinese: "的",
                                traditionalChinese: "的",
                                pinyin: "de5",
                                importance: 9.1273
                            }, {
                                englishTranslations: [{english: "language"}, {english: "CL:門|门[men2],種|种[zhong3]"}],
                                simplifiedChinese: "语言",
                                traditionalChinese: "语言",
                                pinyin: "yu3 yan2",
                                importance: 7.1487
                            }, {
                                englishTranslations: [{english: "I"}, {english: "me"}, {english: "my"}],
                                simplifiedChinese: "我",
                                traditionalChinese: "我",
                                pinyin: "wo3",
                                importance: 9.3219
                            }, {
                                englishTranslations: [{english: "to shout"}, {english: "to call"}, {english: "to order"}, {english: "to ask"}, {english: "to be called"}, {english: "by (indicates agent in the passive mood)"}],
                                simplifiedChinese: "叫",
                                traditionalChinese: "叫",
                                pinyin: "jiao4",
                                importance: 8.961
                            }, {
                                englishTranslations: [{english: "variant of 叫[jiao4]"}],
                                simplifiedChinese: "叫",
                                traditionalChinese: "叫",
                                pinyin: "jiao4",
                                importance: 1.0059
                            }, {
                                englishTranslations: [{english: "teacher"}, {english: "husband"}, {english: "doctor (dialect)"}, {english: "CL:位[wei4]"}],
                                simplifiedChinese: "先生",
                                traditionalChinese: "先生",
                                pinyin: "xian1 sheng5",
                                importance: 7.7763
                            }, {
                                englishTranslations: [{english: "Mister (Mr.)"}],
                                simplifiedChinese: "先生",
                                traditionalChinese: "先生",
                                pinyin: "Xian1 sheng5",
                                importance: 7.5915
                            }, {
                                englishTranslations: [{english: "can"}, {english: "may"}, {english: "possible"}, {english: "able to"}, {english: "not bad"}, {english: "pretty good"}],
                                simplifiedChinese: "可以",
                                traditionalChinese: "可以",
                                pinyin: "ke3 yi3",
                                importance: 8.8271
                            }, {
                                englishTranslations: [{english: "(onom.) dong"}, {english: "ding dong (bell)"}],
                                simplifiedChinese: "当",
                                traditionalChinese: "当",
                                pinyin: "dang1",
                                importance: 7.2193
                            }, {
                                englishTranslations: [{english: "at or in the very same..."}, {english: "suitable"}, {english: "adequate"}, {english: "fitting"}, {english: "proper"}, {english: "to replace"}, {english: "to regard as"}, {english: "to think"}, {english: "to pawn"}, {english: "(coll.) to fail (a student)"}],
                                simplifiedChinese: "当",
                                traditionalChinese: "当",
                                pinyin: "dang4",
                                importance: 8.1439
                            }, {
                                englishTranslations: [{english: "to be"}, {english: "to act as"}, {english: "manage"}, {english: "withstand"}, {english: "when"}, {english: "during"}, {english: "ought"}, {english: "should"}, {english: "match equally"}, {english: "equal"}, {english: "same"}, {english: "obstruct"}, {english: "just at (a time or place)"}, {english: "on the spot"}, {english: "right"}, {english: "just at"}],
                                simplifiedChinese: "当",
                                traditionalChinese: "当",
                                pinyin: "dang1",
                                importance: 7.8493
                            }, {
                                englishTranslations: [{english: "you (informal)"}],
                                simplifiedChinese: "你",
                                traditionalChinese: "你",
                                pinyin: "ni3",
                                importance: 9.2623
                            }, {
                                englishTranslations: [{english: "teacher"}, {english: "CL:個|个[ge4],位[wei4]"}],
                                simplifiedChinese: "老师",
                                traditionalChinese: "老师",
                                pinyin: "lao3 shi1",
                                importance: 8.239
                            }, {
                                englishTranslations: [{english: "surname Jiao"}], 
                                simplifiedChinese: "教",
                                traditionalChinese: "教",
                                pinyin: "Jiao4", 
                                importance: 2.9716
                            }, {
                                englishTranslations: [{english: "to teach"}],
                                simplifiedChinese: "教",
                                traditionalChinese: "教",
                                pinyin: "jiao1",
                                importance: 7.6985
                            }, {
                                englishTranslations: [{english: "religion"}, {english: "teaching"}, {english: "to make"}, {english: "to cause"}, {english: "to tell"}],
                                simplifiedChinese: "教",
                                traditionalChinese: "教",
                                pinyin: "jiao4",
                                importance: 7.3753
                            }, {
                                englishTranslations: [{english: "Chinese character"}, {english: "CL:個|个[ge4]"}, {english: "Japanese: kanji"}, {english: "Korean: hanja"}, {english: "Vietnamese: hán tự"}],
                                simplifiedChinese: "汉字",
                                traditionalChinese: "汉字",
                                pinyin: "han4 zi4",
                                importance: 6.4172
                            }, {
                                englishTranslations: [{english: "to combine a powdery substance (flour, plaster etc) with water"}, {english: "Taiwan pr. [huo4]"}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "huo2",
                                importance: 2.87
                            }, {
                                englishTranslations: [{english: "old variant of 和[he2]"}, {english: "harmonious"}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "he2",
                                importance: 6.9108
                            }, {
                                englishTranslations: [{english: "and"}, {english: "together with"}, {english: "with"}, {english: "sum"}, {english: "union"}, {english: "peace"}, {english: "harmony"}, {english: "Taiwan pr. [han4] when it means \"and\" or \"with\""}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "he2",
                                importance: 8.5166
                            }, {
                                englishTranslations: [{english: "to mix (ingredients) together"}, {english: "to blend"}, {english: "classifier for rinses of clothes"}, {english: "classifier for boilings of medicinal herbs"}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "huo4",
                                importance: 6.9507
                            }, {
                                englishTranslations: [{english: "to compose a poem in reply (to sb's poem) using the same rhyme sequence"}, {english: "to join in the singing"}, {english: "to chime in with others"}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "he4",
                                importance: 3.424
                            }, {
                                englishTranslations: [{english: "surname He"}, {english: "Japanese (food, clothes etc)"}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "He2",
                                importance: 7.3866
                            }, {
                                englishTranslations: [{english: "old variant of 和[he2]"}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "he2",
                                importance: 1.2746
                            }, {
                                englishTranslations: [{english: "to complete a set in mahjong or playing cards"}],
                                simplifiedChinese: "和",
                                traditionalChinese: "和",
                                pinyin: "hu2",
                                importance: 3.7673
                            }, {
                                englishTranslations: [{english: "phonetic writing"}, {english: "pinyin (Chinese romanization)"}],
                                simplifiedChinese: "拼音",
                                traditionalChinese: "拼音",
                                pinyin: "pin1 yin1",
                                importance: 6.1269
                            }, {
                                englishTranslations: [{english: "in the same place"}, {english: "together"}, {english: "with"}, {english: "altogether (in total)"}],
                                simplifiedChinese: "一起",
                                traditionalChinese: "一起",
                                pinyin: "yi1 qi3",
                                importance: 7.9752
                            }, {
                                englishTranslations: [{english: "to learn"}, {english: "to study"}, {english: "to imitate"}, {english: "science"}, {english: "-ology"}],
                                simplifiedChinese: "学",
                                traditionalChinese: "学",
                                pinyin: "xue2",
                                importance: 7.7957
                            }, {
                                englishTranslations: [{english: "bar (loanword) (serving drinks, or providing Internet access etc)"}, {english: "to puff (on a pipe etc)"}, {english: "(onom.) bang"}, {english: "abbr. for 貼吧|贴吧[tie1 ba1]"}],
                                simplifiedChinese: "吧",
                                traditionalChinese: "吧",
                                pinyin: "ba1",
                                importance: 8.6966
                            }, {
                                englishTranslations: [{english: "(modal particle indicating suggestion or surmise)"}, {english: "...right?"}, {english: "...OK?"}, {english: "...I presume."}],
                                simplifiedChinese: "吧",
                                traditionalChinese: "吧",
                                pinyin: "ba5",
                                importance: 8.5705
                            }],
                        title: "Simple Article 1",
                        totalLength: 49,
                        lastIndex: 0,
                        nextIndex: 48,
                        progress: 0
                    }} />
            </div>
        </div>
        <div className="mb-5">
            <RegistrationForm/>
        </div>
    </>);
}