import React, {useEffect, useState} from "react"
import {WordType} from "../generated/graphql/graphql-zeus";
import {Carousel, CarouselControl, CarouselItem, Popover, PopoverBody} from "reactstrap";
import {DictionaryEntry} from "./DictionaryEntry";
import {Segment, SlimWord} from "../helpers/types";
import CharacterWithToneHighlighting from "./CharacterWithToneHighlighting";

function isASCIIOnly(value: string) {
    return /^[\x00-\x7F]*$/.test(value);
}

export default ({segment, words}: { segment: Segment, words: SlimWord[] }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [id, _] = useState('a' + crypto.getRandomValues(new Uint32Array(64)).join(""));
    const [activeIndex, setActiveIndex] = useState(0);

    function setIsOpenFalse(event: MouseEvent) {
        if (event.target instanceof HTMLElement && event.target.id !== id) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        if (isOpen) {
            document.addEventListener('click', setIsOpenFalse);
        } else {
            document.removeEventListener('click', setIsOpenFalse);
        }
    }, [isOpen])

    const next = () => {
        const nextIndex = activeIndex === words.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        const nextIndex = activeIndex === 0 ? words.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    return <span>
        <span
            style={{whiteSpace: 'pre-line'}}
            id={id} tabIndex={0} onClick={(e) => {
            setIsOpen(!isOpen)
        }}
            className="fs-1-5rem">
            <CharacterWithToneHighlighting segment={segment} word={words.length > 0 ? words.sort((a, b) => b.importance - a.importance)[0] : undefined}/>
        </span>
        {segment.word.trim().length === 0 || isASCIIOnly(segment.word) || segment.type === WordType.ENGLISH
            ? null
            : <Popover
                popperClassName="word-info-popover"
                innerClassName="word-info-popover-inner"
                isOpen={isOpen}
                target={`#${id}`}
                placement="bottom"
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation()
                }}>
                <PopoverBody>
                    {words.length == 0 ? <div className="text-center">No results</div>
                        : <Carousel slide={false} activeIndex={activeIndex} next={next} previous={previous} enableTouch>
                            {
                                words.sort((a, b) => b.importance - a.importance).map((w, i) =>
                                    <CarouselItem key={i}>
                                        <div>
                                            <DictionaryEntry selectedWord={w} sideLength={100} autoPlay={i === activeIndex}/>
                                        </div>
                                        <hr />
                                        <div className="d-flex">
                                            <button className="flex-grow-1 btn btn-outline-primary" onClick={() => setIsOpen(false)}>Close</button>
                                        </div>
                                    </CarouselItem>
                                )}
                            {words.length > 1
                                ? <>
                                    <CarouselControl
                                        className="carousel-arrow-left"
                                        directionText="<"
                                        dark
                                        direction="prev"
                                        onClickHandler={previous}
                                    />
                                    <CarouselControl
                                        className="carousel-arrow-right"
                                        direction="next"
                                        dark
                                        directionText=">"
                                        onClickHandler={next}
                                    />
                                </>
                                : null}
                        </Carousel>
                    }
                </PopoverBody>
            </Popover>
        }
    </span>
}