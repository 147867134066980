import {Segment, SlimWord} from "../helpers/types";
import React from "react";
import {getPinyinColors} from "../helpers/utils";

export default ({segment, word}: { segment: Segment, word?: SlimWord }) => {
    if (!word) {
        return <span style={{pointerEvents: "none"}}>{segment.word}</span>;
    }

    const pinyin = word?.pinyin;
    const chinese = segment.word;
    const tones = getPinyinColors(pinyin);
    const characters = chinese.split('');

    if (tones.length != characters.length) {
        return <span style={{pointerEvents: "none"}}>{segment.word}</span>;
    }


    return <>
        {tones.map((tone, index) => <span key={index} style={{color: tone, pointerEvents: "none"}}>{characters[index]}</span>)}
    </>;
}