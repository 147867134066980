import * as React from 'react';
import {useEffect, useState} from 'react';
import {Link, Navigate, useParams} from "react-router-dom";
import {urls} from "../urls";
import {postJson} from "../helpers/web";
import {LoaderOverlay} from "./CommonSubComponents";
import {setUserProfile, useAppDispatch, useAppSelector} from "../store";


export const SubscriptionSuccess = () => {
    const signedIn: boolean = useAppSelector(state => state.account.signedIn);
    const {checkoutSessionId} = useParams<{ checkoutSessionId: string }>();
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (checkoutSessionId) {
            postJson(urls.api.stripe.subscription.verify, {checkoutSessionId: checkoutSessionId})
                .then((webResult) => {
                    if (webResult.webRequestResult.success) {

                        dispatch(setUserProfile({json: webResult.webRequestResult.successData}));
                    } else {
                        setErrorMessage(webResult.webRequestResult.errorMessage)
                    }

                    setLoading(false)
                });
        }
    }, [checkoutSessionId])

    if (!signedIn) {
        return <Navigate to={urls.account.signin}/>
    } else if (errorMessage) {
        return <div className="row full-height justify-content-center align-items-center">
            <div className="card shadow">
                <div className="card-body text-center p-5">
                    <div className="alert alert-danger">We couldn't verify your subscription. Please wait a couple of minutes and try signing back in. If the problem persists please contact us for assistance.</div>
                </div>
            </div>
        </div>
    } else {
        return <div className="row full-height justify-content-center align-items-center">
            {loading ? <LoaderOverlay loading={loading} />
                : <div className="card shadow">
                    <div className="card-body text-center p-5">
                        <h1>Thank you for joining Mister Mandarin!</h1>
                        <Link
                            id='continue-from-subscribe-success-link'
                            className="btn btn-huge shadow btn-primary mt-5"
                            to={urls.dashboard}>Start Learning Now</Link>
                    </div>
                </div>
            }
        </div>
    }
}
