import * as React from 'react';
import {useState} from 'react';
import {useDispatch} from "react-redux";
import {isAndroid, processGooglePlayPayment, redirectToStripe, SubscriptionPlan} from "../helpers/payment";
import {urls} from "../urls";
import {useNavigate} from "react-router-dom";
import {setDesiredSubscriptionPlan, useAppSelector} from "../store";

const SubscriptionPlans = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const signedIn = useAppSelector(a => a.account.signedIn);
    const emailConfirmed = useAppSelector(a => a.account.emailConfirmed);
    const [paymentError, setPaymentError] = useState<string | undefined>();


    const handleStartFreeTrialClick = (plan: SubscriptionPlan) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        dispatch(setDesiredSubscriptionPlan({plan: plan}))

        if (signedIn && emailConfirmed) {
            if (isAndroid()) {
                const success = await processGooglePlayPayment(plan)

                if (success) {
                    navigate(urls.dashboard);
                }
            } else {
                const result = await redirectToStripe(plan);

                if (result === undefined || result.error) {
                    // If `redirectToPayment` fails due to a browser or network
                    // error, display the localized error message to your customer.
                    setPaymentError(result?.error?.message || "An error occurred");
                    return;
                }
            }
        } else if (signedIn && !emailConfirmed) {
            navigate(urls.account.confirm)
        } else {
            navigate(urls.account.register)
        }
    };

    return <div className="row text-center mt-5 mb-5">
        <div className="col-6">
            <div className="card box-shadow">
                <div className="card-header pb-0"><h2 className="my-0 anton">Monthly</h2></div>
                <div className="card-body">
                    <h1 className="card-title pricing-card-title">$2 <small className="text-muted">/ month</small></h1>
                    <ul className="list-unstyled mt-3 mb-4">
                        <li>Learn to speak Chinese</li>
                        <li>Practice writing Chinese</li>
                        <li>Start reading Chinese</li>
                        <li>&nbsp;</li>
                    </ul>
                    <div className="d-grid g-0">
                        <button
                            id="plan-1-button"
                            className="btn btn-outline-primary mt-3 anton fs-1-5rem"
                            role="link"
                            onClick={handleStartFreeTrialClick('monthly')}
                        >Subscribe Now
                        </button>
                    </div>
                    <div id="error-message"/>
                </div>
            </div>
        </div>
        <div className="col-6">
            <div className="card box-shadow">
                <div className="card-header pb-0"><strong><h2 className="my-0 anton">Annual</h2></strong>
                </div>
                <div className="card-body">
                    <h1 className="card-title pricing-card-title">$12 <small className="text-muted">/ year</small></h1>
                    <ul className="list-unstyled mt-3 mb-4">
                        <li>Learn to speak Chinese</li>
                        <li>Practice writing Chinese</li>
                        <li>Start reading Chinese</li>
                        <li><strong>Save $12 per year</strong></li>
                    </ul>
                    <div className="d-grid g-0">
                        <button className="btn btn-primary mt-3 anton fs-1-5rem"
                                onClick={handleStartFreeTrialClick('annual')}>Subscribe Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default SubscriptionPlans;
