import WordInfo from "./WordInfo";
import React from "react";
import {Page} from "../helpers/types";
import useCharacterPreference from "../hooks/useCharacterPreference";

export default ({page}: {page: Page}) => {
    const characterTypePreference = useCharacterPreference();
    
    return <div className="p-2 mb-1 article-page">
        {page.segments?.map((s, i) =>
            <WordInfo 
                key={i} 
                segment={s} 
                words={page.chineseWords
                    .filter(c => c.simplifiedChinese === s.word || c.traditionalChinese == s.word)
                    .map(c => ({...c, chinese: characterTypePreference === "Simplified" ? c.simplifiedChinese : c.traditionalChinese }))}/>)
        }
    </div>
}