import {QuizWord} from "./Quiz";
import {
    anyTranslationMatches,
    checkIfTouch,
    getWidthOfWindow,
    pinyinMatches
} from "../helpers/utils";
import CharacterAudioComponent from "./CharacterAudioComponent";
import React, {useEffect, useRef, useState} from "react";
import {calculateQuizSideLength} from "../helpers/utils";
import Translations from "./Translations";
import CharacterDisplay from "./CharacterDisplay";
import Pinyin from "./Pinyin";
import useEnter from "../hooks/useEnter";

const answerIsCorrect = (word: QuizWord, english: string, pinyin: string) => anyTranslationMatches(word.englishTranslations, english) && pinyinMatches(word.pinyin, pinyin);

function CharacterRow({word}: { word: QuizWord }) {
    const quizSideLength = calculateQuizSideLength();
    if (word.chinese.length == 1) {
        return <div className="col flex-grow-0">
            <CharacterDisplay word={word} animationOptions={{animate: true}} addShadow={false} sideLength={quizSideLength} showTianZeGe={true} context={"Presentation"}
                              showCharacter={false} showOutline={true}/>
        </div>
    } else {
        const paddingFactor = document.documentElement.clientWidth < 480 ? 45 / word.chinese.length : 75 / word.chinese.length;
        const adjustedLength = Math.min(250, Math.max(80, Math.min(document.documentElement.clientWidth, 600) / word.chinese.length - paddingFactor * word.chinese.length));
        return <div className="col-12 text-center">
            <CharacterDisplay word={word} animationOptions={{animate: true}} addShadow={false} sideLength={adjustedLength} showTianZeGe={true} context={"Presentation"}
                              showCharacter={false} showOutline={true}/>
        </div>
    }
}

export default function Presentation(props: { word: QuizWord, autoFocus: boolean, onCompleted: (completedWord: QuizWord, answerWasCorrect: boolean) => void, title: string }) {
    const word = props.word;
    const inputRef = useRef<HTMLInputElement>(null);
    const [pinyin, setPinyin] = useState<string>('');
    const [english, setEnglish] = useState<string>('');
    const enterClicked = useEnter();

    // TODO: work out how to generalise (ComprehensionQuiz/PinyinQuiz)
    useEffect(() => {
        if (props.autoFocus && !checkIfTouch()) {
            inputRef?.current?.focus();
        }
    }, [props.autoFocus]);

    useEffect(() => {
        if (enterClicked && answerIsCorrect(word, english, pinyin)) {
            props.onCompleted(word, answerIsCorrect(word, english, pinyin));
        }
    }, [enterClicked])

    return (<>
        <div className="card-header text-center pb-0 mb-0">
            <h1>{props.title}</h1>
            <hr/>
        </div>
        <div className="card-body p-0">
            <div className="row justify-content-center">
                <CharacterRow word={word}/>
                <div className="col text-start flex-grow-1">
                    <div className="mb-2 mt-2">
                        <div className="d-flex">
                            <h4 className="flex-grow-1">Pinyin</h4>
                            <CharacterAudioComponent pinyin={word.pinyin} showPlayButton={true} className="fs-2-1rem" autoPlay/>
                        </div>
                        <div className="fs-1-2rem font-weight-bold">
                            <Pinyin pinyin={word.pinyin}/>
                        </div>
                    </div>
                    <hr/>
                    <div className="mb-2" style={{maxWidth: '35rem'}}>
                        <h4>Translations</h4>
                        <div>
                            <Translations word={word} style={'inline'}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col">
                    <h3 className="text-center mt-1">Enter the <span className="text-success">Pinyin</span></h3>
                    <input
                        tabIndex={1}
                        ref={inputRef}
                        className={`form-control form-control-lg text-center ${pinyinMatches(word.pinyin, pinyin) ? 'border-answer-correct' : null}`}
                        value={pinyin}
                        onChange={(event) => setPinyin(event.currentTarget.value)}/>
                </div>
            </div>
            {word.englishTranslations.length > 0
                ? <div className="row mt-3">
                    <div className="col">
                        <h3 className="text-center mt-1">Enter the <span className="text-primary">English</span></h3>
                        <input
                            tabIndex={2}
                            className={`form-control form-control-lg text-center ${anyTranslationMatches(word.englishTranslations, english) ? 'border-answer-correct' : null}`}
                            value={english}
                            onChange={(event) => setEnglish(event.currentTarget.value)}/>
                    </div>
                </div>
                : null
            }
            <div className="row mt-4">
                <div className="col">
                    <div className="d-grid g-0">
                        <button className="btn btn-primary btn-lg" disabled={!answerIsCorrect(word, english, pinyin)}
                                onClick={() => props.onCompleted(word, answerIsCorrect(word, english, pinyin))}>Continue
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </>)
}