export const urls = {
    home: '/',
    about: '/about',
    dashboard: '/dashboard',
    dictionary: '/dictionary',
    articles: {
      create: '/article/create',
      read: (articleId: string, index: number) => `/article/${articleId}/${index}`,
      edit:   {
          getEditUrl: (articleId: string) => `/article/edit/${articleId}`,
      }
    },
    privacy: '/privacy',
    terms: '/terms',
    lesson: {
        create: '/lesson/create',
        quiz: (lessonId: string) => `/quiz/${lessonId}`,
        edit: {
            getEditUrl: (lessonId: string) => `/lesson/edit/${lessonId}`,
        }
    },
    account: {
        create: '/account/signin',
        signin: '/account/signin',
        register: '/account/register',
        requestReset: '/account/reset/request',
        resetPassword: '/account/reset/password/:code/:resetToken',
        confirm: '/account/confirm',
        subscribe: '/account/subscribe',
        subscription: '/account/subscription'
    },
    api: {
        account: {
            signOut: '/api/account/signOut',
            socialLogin: '/api/account/socialLogin',
            register: '/api/account/register',
            resendConfirmationCode: "/api/account/resendConfirmationCode",
            confirm: "/api/account/confirm",
            profile: "/api/account/profile"
        },
        billing: {
            manage: '/api/billing/manage'
        },
        stripe: {
            payment: {
                getCheckoutSessionId: '/api/stripe/payment/getCheckoutSessionId',
                getClientSideStripeKey: '/api/stripe/payment/GetClientSideStripeKey'
            },
            subscription: {
                verify: '/api/stripe/subscription/verify'
            }
        },
        google: {
            payment: {
                verify: '/api/google/payment/verify'
            }
        }
    },
    audio: {
        getAudioTagUrlFemale: (pinyin: string) => `https://${urls.audio.pinyin}/${pinyin.replace(/\s/g, '')}_xiaoXiao.mp3`,
        getAudioTagUrlMale: (pinyin: string) => `https://${urls.audio.pinyin}/${pinyin.replace(/\s/g, '')}_yunYang.mp3`,
        pinyin: "media.mistermandarin.com/media/audio/pinyin"
    }
}
