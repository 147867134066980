import * as React from 'react';
import {SyntheticEvent, useRef, useState} from 'react';
import {setEmail} from "../store";
import {postJson} from "../helpers/web";
import {getErrorIfExists, getRequiredEmailForm, getRequiredPassword, LoaderOverlay} from "./CommonSubComponents";
import {useDispatch} from "react-redux";
import {RegisterViewModel} from "../viewModels/RegisterViewModel";
import {throwIfMissing} from "../helpers/safetyChecks";
import {urls} from "../urls";
import SocialLoginProviders from "./SocialLoginProviders";
import {EmailConfirmationForm} from "./EmailConfirmationForm";

export const RegistrationForm = (props: { title?: string }) => {
    const email = useRef<HTMLInputElement>(null)
    const password = useRef<HTMLInputElement>(null)
    const [finalPassword, setFinalPassword] = useState<string | undefined>(undefined)
    const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [registrationComplete, setRegistrationComplete] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();

    const postRegistration = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);

        const data: RegisterViewModel = {
            agreedToTermsAndConditions: agreedToTermsAndConditions,
            email: throwIfMissing(email.current).value,
            password: throwIfMissing(password.current).value
        };

        const result = await postJson(urls.api.account.register, data);

        if (result.webRequestResult.success) {
            if (email.current) {
                dispatch(setEmail({email: email.current.value}))
            }
            setFinalPassword(password.current?.value)
            setRegistrationComplete(true);
            setErrorMessage(undefined);
        } else {
            setErrorMessage(result.webRequestResult.errorMessage);
        }

        setLoading(false);
    };


    const registrationForm =
        <div className="card mt-5 mb-5 p-3 box-shadow">
            <div className="card-header text-center">
                <h1 id="registration-form-header" className="mb-0 mt-2">{props.title || "Start learning now!"}</h1>
                <hr/>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        {getErrorIfExists(errorMessage)}
                    </div>
                </div>
                <div className="row">
                    <div className="col border-right border-light">
                        <form onSubmit={postRegistration}>
                            <LoaderOverlay loading={loading} />
                            <fieldset className="row gy-3" disabled={loading}>
                                <h2 className="col-12 text-center">Mister Mandarin</h2>
                                <div className="col-12">
                                    {getRequiredEmailForm(email)}
                                </div>
                                <div className="col-12">
                                    {getRequiredPassword(password)}
                                    <small className="text-muted">Your password must contain an uppercase character,
                                        lowercase
                                        character, a digit, and a non-alphanumeric character.</small>
                                </div>
                                <div className="col-12">
                                    <div className="form-check ">
                                        <input
                                            name="termsAndConditions"
                                            type="checkbox"
                                            checked={agreedToTermsAndConditions}
                                            onChange={(value: React.ChangeEvent<HTMLInputElement>) => setAgreedToTermsAndConditions(value.currentTarget.checked)}
                                            required={true}
                                            className="form-check-input"/>
                                        <label htmlFor="termsAndConditions">I accept the
                                            <a href={urls.terms}
                                               target="_blank"
                                               rel="noreferrer"> terms of
                                                service</a></label>
                                    </div>
                                </div>
                                <div className="col-12 d-grid text-center">
                                    <button id="register-button"
                                            className="btn btn-success anton fs-1-5rem">Register
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <SocialLoginProviders setErrorMessage={setErrorMessage}/>
                </div>
            </div>
        </div>;

    if (!registrationComplete) {
        return registrationForm;
    } else {
        return <EmailConfirmationForm passwordFromRegistration={finalPassword}/>
    }
}
