import React from "react";
import {softAssertNever} from "../helpers/safetyChecks";
import {getTranslationsFromWord} from "../helpers/utils";

export type TranslationStyle = 'inline' | 'badge'

export default (props: { word: { preferredTranslations?: ReadonlyArray<{ english: string }>, englishTranslations: ReadonlyArray<{ english: string }> }, style: TranslationStyle }) => {
    const translations = getTranslationsFromWord(props.word);

    return <>{[...translations].sort((a, b) => a.english.length - b.english.length).map((translation, index) => {

        switch (props.style) {
            case "inline":
                return <span className="d-inline-block translation" style={{whiteSpace: 'break-spaces'}}
                             key={index}>{index > 0 ? ' / ' : null}{translation.english}</span>
            case "badge":
                return <span
                    className="badge bg-info subtle-shadow m-1 fs-1-5rem"
                    style={{whiteSpace: 'break-spaces'}}
                    key={index}>{translation.english}</span>
            default:
                softAssertNever(props.style)
                return <></>

        }
    })
    }</>
}