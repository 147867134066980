import React, {useEffect, useRef, useState} from "react";
import {postJson} from "../helpers/web";
import {urls} from "../urls";
import {WebRequestResult} from "../viewModels/WebResult";

export default function (props: { handleResponse: (webRequestResult: WebRequestResult) => void }) {
    const facebookButtonRef = useRef<HTMLButtonElement>(null);
    const [initComplete, setInitComplete] = useState<boolean>(false);

    useEffect(() => {
        const handle = setInterval(() => {
            if (window.FB && facebookButtonRef.current) {
                window.FB.init({
                    appId: '242610311406138',
                    cookie: true,
                    xfbml: true,
                    version: 'v13.0',
                });

                clearInterval(handle);
                setInitComplete(true);
            }
        }, 300)
    }, [])


    async function loginToServer(statusResponse: facebook.StatusResponse) {
        const response = await postJson(urls.api.account.socialLogin, {
            ...statusResponse.authResponse,
            provider: 'Facebook'
        });

        props.handleResponse(response.webRequestResult);
    }

    function loginToFacebook() {
        if (initComplete) {
            FB.login((statusResponse) => {
                if (statusResponse.status == 'connected') {
                    loginToServer(statusResponse);
                }
            });
        }
    }

    return <button
        id="facebook-login-button"
        ref={facebookButtonRef}
        type="button" className="d-flex align-items-center btn btn-light shadow-none"
        onClick={loginToFacebook}
        style={{
            fontFamily: "'Google Sans', arial, sans-serif",
            fontWeight: 500,
            fontSize: 14,
            background: 'white',
            borderColor: '#DADCE0',
            color: "#3c4043",
            lineHeight: "24px" 
        }}>
        <i className="icon-facebook-official d-inline-block float-left fs-1-5rem"
           style={{color: "#3b5998", marginTop: -3, marginBottom: -6, marginLeft: -7}}/>
        <span className="d-inline-block flex-grow-1 text-nowrap"
              style={{lineHeight: 2}}>Continue with Facebook</span>
    </button>

}