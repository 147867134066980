import * as React from 'react';
import {SyntheticEvent, useRef, useState} from 'react';
import {Navigate} from 'react-router';
import {setEmail, setUserProfile} from "../store";
import {postJson} from "../helpers/web";
import {getErrorIfExists, getRedirectToEmailConfirmation, getRequiredEmailForm, getRequiredPassword, LoaderOverlay} from "./CommonSubComponents";
import {SignInViewModel} from "../viewModels/SignInViewModel";
import {ErrorCode} from "../viewModels/ErrorCode";
import {Link} from "react-router-dom";
import {throwIfMissing} from "../helpers/safetyChecks";
import {urls} from "../urls";
import {WebRequestResult} from "../viewModels/WebResult";
import SocialLoginProviders from "./SocialLoginProviders";
import {useAppDispatch, useAppSelector} from "../store";

export const SignInForm = () => {
    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);
    const rememberMe = useRef<HTMLInputElement>(null);
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [needsEmailConfirmation, setNeedsEmailConfirmation] = useState<boolean>(false);
    const signedIn = useAppSelector(a => a.account.signedIn);
    const dispatch = useAppDispatch()

    function handleSignInResponse(webRequestResult: WebRequestResult) {
        if (webRequestResult.success) {
            setErrorMessage(undefined)
            if (email.current) {
                dispatch(setEmail({email: email.current.value}));
            }
            dispatch(setUserProfile({json: webRequestResult.successData}));
            return;
        }

        setErrorMessage(webRequestResult.errorMessage);

        const needsEmailConfirmation = webRequestResult.errorCode == ErrorCode.EmailNotConfirmed;

        if (needsEmailConfirmation) {
            if (email.current) {
                dispatch(setEmail({email: email.current.value}));
            }
            setNeedsEmailConfirmation(true);
        }
    }

    async function postSignIn(event: SyntheticEvent) {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);

        const data: SignInViewModel = {
            email: throwIfMissing(email.current).value,
            password: throwIfMissing(password.current).value,
            rememberMe: throwIfMissing(rememberMe.current).checked
        };

        const result = await postJson("/api/account/signin", data);
        setLoading(false);
        handleSignInResponse(result.webRequestResult);
    }


    if (signedIn) {
        return (
            <Navigate to={urls.dashboard}/>
        )
    } else if (needsEmailConfirmation) {
        return getRedirectToEmailConfirmation();
    } else {
        return (
            <div className="card mt-5 mb-5 p-3 box-shadow">
                <div className="card-header text-center">
                    <h1 id="registration-form-header" className="mb-0 mt-2">Welcome back!</h1>
                    <hr/>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col pl-5 pr-5">
                            {getErrorIfExists(errorMessage)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col border-right border-light">
                            <form onSubmit={postSignIn}>
                                <LoaderOverlay loading={loading} />
                                <fieldset className="row gy-3" disabled={loading}>
                                    <h2 className="col-12 text-center">Mister Mandarin</h2>
                                    <div className="col-12">
                                        {getRequiredEmailForm(email)}
                                    </div>
                                    <div className="col-12">
                                        {getRequiredPassword(password)}
                                    </div>
                                    <div>
                                        <div className="form-check ">
                                            <input
                                                id="rememberMe"
                                                name="rememberMe"
                                                type="checkbox"
                                                ref={rememberMe}
                                                className="form-check-input"/>
                                            <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="d-grid g-0">
                                            <button id="sign-in-button" className="btn btn-primary">
                                                Sign In
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 text-center">
                                        <Link to={urls.account.requestReset}>Forgot your password?</Link>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                        <SocialLoginProviders setErrorMessage={setErrorMessage}/>
                    </div>
                </div>
            </div>
        );
    }
}
