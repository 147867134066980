import {Result, WebRequestResult} from "../viewModels/WebResult";

function getCommonHeaders() {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    return headers;
}

export async function patchJson<T>(url: string, body: T): Promise<{ response: Response, webRequestResult: WebRequestResult }> {
    return sendWithBody(url, body, 'PATCH');
}
export async function putJson<T>(url: string, body: T): Promise<{ response: Response, webRequestResult: WebRequestResult }> {
    return sendWithBody(url, body, 'PUT');
}

export async function postJson<T>(url: string, body: T): Promise<{ response: Response, webRequestResult: WebRequestResult }> {
    return sendWithBody(url, body, 'POST');
}

export async function sendWithBody<T>(url: string, body: T, method: 'PUT' | 'POST' | 'PATCH'): Promise<{ response: Response, webRequestResult: WebRequestResult }> {
    const headers = getCommonHeaders();

    const response = await fetch(url, {
        method: method,
        body: JSON.stringify(body),
        headers: headers
    });

    const webRequestResult: WebRequestResult = await parseJson(response);

    return {response, webRequestResult};
}

export async function getJson(url: string): Promise<Result<WebRequestResult>> {
    const headers = getCommonHeaders();

    const response = await fetch(url, {
        headers: headers
    });

    if (response.status === 200) {
        return {
            data: await parseJson(response),
            response: response,
            type: 'success'
        };
    }

    return {response, type: 'failure'};
}


async function parseJson(response: Response): Promise<WebRequestResult> {
    const text = await response.text();

    try {

        if (text) {
            return JSON.parse(text);
        }
    }
    catch
        (error)
        {
            console.error(error)
        }

        return {
            success: false,
            errorMessage: "An error occured while processing your request. Please try again.",
            successData: '{}',
            errorCode: 'UNKNOWN'
        }
    }