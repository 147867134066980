import * as React from 'react';
import About from "./About";
import {RegistrationForm} from "./RegistrationForm";

export default function () {
    return <>
        <div className="mb-5">
            <RegistrationForm/>
        </div>
        <About/>
    </>
}
