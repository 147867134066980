import * as React from 'react';
import {Suspense} from 'react';
import {Provider} from 'react-redux';
import App from './App';
import registerServiceWorker from './serviceWorkerRegistration';
import {ErrorBoundary} from "./components/ErrorBoundary";
import {LoaderOverlay} from "./components/CommonSubComponents";
import {createBrowserHistory} from "history";
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {BrowserRouter} from "react-router-dom";
import {store} from "./store";
import {createRoot} from "react-dom/client";
import {ClickAnalyticsPlugin} from "@microsoft/applicationinsights-clickanalytics-js";

const browserHistory = createBrowserHistory({ basename: '' });

const reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
const clickPluginInstance = new ClickAnalyticsPlugin();
   const clickPluginConfig = {
     autoCapture: true
};
const appInsights = new ApplicationInsights({
    config: {
        connectionString: 'InstrumentationKey=02c41441-b3cd-4379-aba0-95e3b2ddeddb;IngestionEndpoint=https://eastus-8.in.applicationinsights.azure.com/;LiveEndpoint=https://eastus.livediagnostics.monitor.azure.com/;ApplicationId=e946d8e0-14b7-4463-b17c-069531b1267d',
        // *** If you're adding the Click Analytics plug-in, delete the next line. ***
        extensions: [reactPlugin, clickPluginInstance],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
            [clickPluginInstance.identifier]: clickPluginConfig
        }
    }
});
appInsights.loadAppInsights();

const element = document.getElementById('root');

if (!element) {
    throw new Error("root element missing");
}

const root = createRoot(element);

root.render(<Provider store={store}>
    <BrowserRouter>
        <Suspense fallback={<LoaderOverlay loading={true}/>}>
            <ErrorBoundary>
                <App/>
            </ErrorBoundary>
        </Suspense>
    </BrowserRouter>
</Provider>)

registerServiceWorker();

// Google tag manager
function initGTM(w: any, d: any, s: any, l: any, i: any) {
    w[l] = w[l] || [];
    w[l].push({
        'gtm.start':
            new Date().getTime(), event: 'gtm.js'
    });
    var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src =
        'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
}

initGTM(window, document, 'script', 'dataLayer', 'GTM-N7RJ4DL');