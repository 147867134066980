import React from "react"
import HandwritingQuiz from "./HandwritingQuiz";
import {QuizType} from "../generated/graphql/graphql-zeus";

export default function (props: { onCompleted: () => void }) {
    return <div className="card mb-5 p-3 box-shadow">
        <div className="card-header text-center">
            <h1 className="mb-0 mt-2">Write your first character</h1>
            <hr/>
        </div>
        <div className="card-body">
            <div className="d-flex justify-content-center">
                <div>
                    <HandwritingQuiz
                        word={{
                            chineseWordId: '123',
                            progress: 0,
                            quizType: QuizType.HANDWRITING,
                            pinyin: 'yi1',
                            index: 0,
                            chinese: '一',
                            englishTranslations: [{english: 'one', id: '', importance: 10}],
                            preferredTranslations: [],
                            strokeCount: 1
                        }}
                        onCompleted={props.onCompleted}/>
                </div>
            </div>
        </div>
    </div>
}