import * as React from "react";
import {useEffect} from "react";
import {delay, getPinyinColors, HanziWriterOptions} from "../helpers/utils";
import {Guid} from "../generated/graphql/graphql-zeus";
import HanziWriter, {QuizOptions} from "hanzi-writer";

/**
 * Generates an individual hanziWriter for each character in a string
 * Assumes that a matching series of Tianzige have been generated using
 * generateTianZiGe in CommonSubComponents.tsx
 */

function createHanziWriters(characterId: string, simplifiedChinese: string, options: HanziWriterOptions, characterColors: string[]): HanziWriter[] {
    options.onLoadCharDataError = () => {
        console.log(`Couldn't load character data for ${characterId}`);
        window.location.reload();
    }

    options.showHintAfterMisses = 3;

    const hanziWriters: HanziWriter[] = [];
    for (let i = 0; i < simplifiedChinese.length; i++) {
        if (characterColors.length > i) {
            options.strokeColor = characterColors[i];
        }

        const newWriter = HanziWriter.create(
            characterId + i,
            simplifiedChinese[i],
            options);
        hanziWriters.push(newWriter);
    }
    return hanziWriters;
}

const ChineseCharacters = ({
                               id,
                               chinese,
                               sideLength
                           }: { id: string, chinese: string, sideLength: number }) => {
    return <>{Array.from(chinese).map((_, i) => <div key={id + i} id={id + i} className="character-svg forced-layer d-inline-block" style={{width: sideLength, height: sideLength}}>
    </div>)}</>
};

const TianZeGe = ({
                      id,
                      chinese,
                      sideLength,
                      addShadow
                  }: { id: string, chinese: string, sideLength: number, addShadow: boolean }) => {
    return <>{Array.from(chinese).map((_, i) => <div key={id + i} id={id + i} className={`${addShadow ? 'shadow-sm' : null} character-svg m-2 d-inline-block`} style={{width: sideLength, height: sideLength, backgroundImage: 'url("https://media.mistermandarin.com/media/images/tianzige.png")', backgroundSize: "cover"}}>
    </div>)}</>
};

// context is used to generate a unique key even when characters are rendered as children of different components. Find a better solution?
export default ({
                    word,
                    sideLength,
                    addShadow,
                    animationOptions,
                    context,
                    showTianZeGe,
                    showCharacter,
                    showOutline,
                    hideColors,
                    quizOptions,
                }:
                    {
                        word: {
                            chinese: string,
                            strokeCount?: number,
                            chineseWordId: Guid,
                            pinyin?: string
                        },
                        sideLength: number,
                        addShadow: boolean,
                        showTianZeGe: boolean,
                        animationOptions: {
                            stopAfter?: number,
                            animate: boolean,
                            animationSpeed?: number,
                        },
                        context: string,
                        showCharacter: boolean,
                        showOutline: boolean,
                        hideColors?: boolean,
                        quizOptions?: QuizOptions
                    }) => {
    const createCharacters = async () => {
        const characterColors = word.pinyin && !hideColors ? getPinyinColors(word.pinyin) : [];

        if (word) {
            const writers = createHanziWriters(word.chineseWordId + context, word.chinese, {
                // Be more lenient for more complex characters because learners are probably practicing memorising strokes
                // rather than practicing their handwriting
                leniency: word.strokeCount !== undefined && word.strokeCount > 5 ? 1.4 : 1,
                showCharacter: showCharacter,
                showOutline: showOutline,
                padding: 5,
                strokeAnimationSpeed: animationOptions?.animationSpeed ?? 1,
                width: sideLength,
                height: sideLength,
                delayBetweenLoops: 100,
            }, characterColors);

            if (animationOptions.animate) {
                const stopAfter = animationOptions?.stopAfter ?? Number.POSITIVE_INFINITY;
                for (let times = 0; times < stopAfter; times++) {
                    for (const w of writers) {
                        await w.animateCharacter();
                    }
                }
            }

            if (quizOptions) {
                writers.forEach((w, i) => w.quiz({
                    onComplete: async (result) => {
                        await w.updateColor("strokeColor", characterColors[i] ?? "#00AA00");
                        await w.updateColor("radicalColor", characterColors[i] ?? "#00AA00");
                        await delay(1000);
                        await quizOptions.onComplete(result);
                    },
                    onCorrectStroke: quizOptions.onCorrectStroke,
                    onMistake: async (data) => {
                        if (data.totalMistakes > 5) {
                            await w.showOutline();
                        }
                        await quizOptions.onMistake(data);
                    }
                }));
            }
        }
    }

    useEffect(() => {
        createCharacters();
    }, [])

    return showTianZeGe
        ? <TianZeGe id={word.chineseWordId + context} chinese={word.chinese} sideLength={sideLength}
                    addShadow={addShadow}/>
        : <ChineseCharacters id={word.chineseWordId + context} chinese={word.chinese}
                             sideLength={sideLength}/>;
}