import * as React from 'react';
import {Container} from 'reactstrap';
import NavMenu from './NavMenu';
import Footer from "./Footer";

export const StandardLayout = function (props: { children?: React.ReactNode }) {

    return <><NavMenu logoVisible={true}/>
        <div id="main-content-wrapper">
            <Container id="container" className={"margin-below-logo"}>
                {props.children}
            </Container>
        </div>
        <Footer/>
    </>
}

export const MinimalLayout = function (props: { children?: React.ReactNode }) {
    return <div className="d-flex flex-column min-vh-100">
        <NavMenu logoVisible={false}/>
        <div id="main-content-wrapper" className="d-flex">
            <Container id="container" fluid={true} className="p-0">
                {props.children}
            </Container>
        </div>
    </div>
}
