import {useEffect, useState} from "react";

export default  () => {
    const [enterClicked, setEnterClicked] = useState<boolean>(false);

    useEffect(() => {
        document.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                setEnterClicked(true)
            }
        })
    }, [])
    
    return enterClicked;
}