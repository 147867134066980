import * as React from 'react';
import {connect} from 'react-redux';
import {NavLink} from "react-router-dom";

const Footer = () => (
    <footer className="footer bg-dark p-2" style={{zIndex: 2}}>
        <div className="container-fluid">
            <div className="row text-center">
                <div className="col-6">
                    <NavLink to="/privacy" className="text-light">Privacy Policy</NavLink>
                </div>
                <div className="col-6">
                    <a href="https://www.facebook.com/mistermandarincom" className="text-light">
                        <h4>
                            <i className="icon-facebook-official"/>
                        </h4>
                    </a>
                </div>
            </div>
            <div className="row text-center bg-dark">
                <div className="col-12">
                    <small className="text-muted">&copy; 2020 Mister Mandarin</small>
                </div>
            </div>
        </div>
    </footer>
);

export default connect()(Footer);
