import {SlimWord, Word} from "../helpers/types";
import React, {useState} from "react";
import CharacterDisplay from "./CharacterDisplay";
import Pinyin from "./Pinyin";
import CharacterAudioComponent from "./CharacterAudioComponent";
import Translations from "./Translations";

export function DictionaryEntry({selectedWord, sideLength, autoPlay}: { selectedWord:  SlimWord, sideLength: number, autoPlay: boolean }) {
    const [animate, setAnimate] = useState<boolean>(false);
    const [id, _] = useState('a' + crypto.getRandomValues(new Uint32Array(64)).join(""));

    return <div className='row'>
        <div className='col-xs-12 col-sm-auto mb-4 text-center' key={id + animate}>
            <CharacterDisplay
                key={selectedWord.chinese}
                sideLength={sideLength}
                addShadow={true}
                showTianZeGe={true}
                animationOptions={{animate: animate}}
                context={''}
                showCharacter={true}
                showOutline={false}
                word={{
                    chinese: selectedWord.chinese,
                    chineseWordId: id,
                    pinyin: selectedWord.pinyin
                }}/>
            <div className="d-grid g-0">
                <button className="btn btn-primary" onClick={() => setAnimate(!animate)}>Strokes</button>
            </div>
        </div>
        <div className='col ml-2 mt-2'>
            <h5>Pinyin</h5>
            <div className='fs-1-5rem'>
                <h5>
                    <span className="mr-2">
                        <Pinyin pinyin={selectedWord.pinyin} />
                    </span>
                    <CharacterAudioComponent pinyin={selectedWord.pinyin} showPlayButton={true} autoPlay={autoPlay} /></h5>
            </div>
            <h5 className="mt-4">Translations</h5>
            <div><Translations word={selectedWord} style={'inline'}/></div>
        </div>
    </div>;
}