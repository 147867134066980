import FacebookLogin from "./FacebookLogin";
import GoogleLogin from "./GoogleLogin";
import * as React from "react";
import {WebRequestResult} from "../viewModels/WebResult";
import {setUserProfile} from "../store";
import {useDispatch} from "react-redux";

export default function ({setErrorMessage}: { setErrorMessage: (message: string | undefined) => void }) {
    const dispatch = useDispatch();

    function handleSignInResponse(webRequestResult: WebRequestResult) {
        if (webRequestResult.success) {
            setErrorMessage(undefined)
            dispatch(setUserProfile({json: webRequestResult.successData}))
            return;
        }

        setErrorMessage(webRequestResult.errorMessage);
    }

    return <div className="col border-left border-light">
        <h2 className="text-center">Social Logins</h2>
        <div className="d-grid g-0 mt-5" style={{maxWidth: 400, margin: "0 auto"}}>
            <FacebookLogin handleResponse={handleSignInResponse}/>
            <div className="mt-3"></div>
            <GoogleLogin handleResponse={handleSignInResponse}/>
        </div>
    </div>
}