import {getJson, postJson} from "./web";
import {urls} from "../urls";
import {loadStripe} from '@stripe/stripe-js/pure'
import { StripeError } from "@stripe/stripe-js";

// ************* DO NOT IGNORE *************
// MUST SAY ALIGNED WITH THE GOOGLE PAY SKUS
export type SubscriptionPlan = 'annual' | 'monthly';

export async function processGooglePlayPayment(planType: SubscriptionPlan): Promise<boolean> {
        const paymentMethods = [{
            supportedMethods: "https://play.google.com/billing",
            data: {
                sku: planType
            }
        }];

        const paymentDetails = {
            total: {
                label: `Total`,
                amount: {currency: `USD`, value: `0`}
            }
        };

        const request = new PaymentRequest(paymentMethods, paymentDetails);

        const paymentResponse = await request.show();
        const {purchaseToken} = paymentResponse.details;
        
        const result = await postJson(urls.api.google.payment.verify, {purchaseToken: purchaseToken, subscriptionId: planType})

        return result.webRequestResult.success;
}

export async function redirectToStripe(planType: "annual" | "monthly"): Promise<{ error: StripeError } | undefined> {
    const webResult = await getJson(urls.api.stripe.payment.getClientSideStripeKey);

    switch (webResult.type) {
        case "failure":
            return;
        case "success":
            const clientSideKey = webResult.data.successData
            const stripe = await loadStripe(clientSideKey);
            
            if(!stripe) {
                return;
            }
            
            const result = await postJson(`${urls.api.stripe.payment.getCheckoutSessionId}?subscriptionType=${planType}`, {});
            return await stripe.redirectToCheckout({
                sessionId: result.webRequestResult.successData,
            });
    }
}

export function isAndroid() {
    return document.referrer.includes('android-app://');
}
