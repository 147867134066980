import * as React from 'react';
import {useState} from 'react';
import {Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink} from 'reactstrap';
import * as ReactRouter from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import {urls} from "../urls";
import {postJson} from "../helpers/web";
import {signOutUser, useAppDispatch, useAppSelector} from "../store";
import CharacterTypeSelector from "./CharacterTypeSelector";


export default ({logoVisible}: { logoVisible: boolean }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const signedIn = useAppSelector(a => a.account.signedIn);
    const dispatch = useAppDispatch();
    const navigate = useNavigate()

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const signOut = async () => {
        const result = await postJson(urls.api.account.signOut, undefined)

        if (result.webRequestResult.success) {
            dispatch(signOutUser());
            navigate(urls.about)
        }
    }

    return <header>
        <Navbar className="navbar-expand-lg navbar-toggleable-lg border-bottom box-shadow bg-white"
                light
                style={{zIndex: 2}}>
            {signedIn
                ?
                <NavLink id="dashboard-nav" tag={ReactRouter.NavLink} className="text-dark btn btn-link" to="/dashboard"
                         path="/dashboard"
                >
                    Dashboard
                </NavLink>
                : <NavLink tag={ReactRouter.NavLink} className="text-dark btn btn-link" to="/" path="/"
                >
                    Home
                </NavLink>}
            {logoVisible && !isOpen
                ? <NavLink tag={ReactRouter.NavLink} to="/">
                    <img alt="Mister Mandarin Logo" className="logo"
                         src="https://media.mistermandarin.com/media/images/LogoRing_144x144_compressed.png"
                         height={122} width={122}/>
                </NavLink>
                : null
            }
            <NavbarToggler onClick={toggle} className="mr-2"/>
            <Collapse className="d-xl-inline-flex flex-row-reverse" isOpen={isOpen} navbar>
                <ul className="navbar-nav flex-grow">
                    <NavItem>
                        <NavLink id="dictionary-nav" tag={ReactRouter.NavLink} className="text-dark btn btn-link"
                                 to={urls.dictionary} path={urls.dictionary}
                        >
                            Dictionary
                        </NavLink>
                    </NavItem>
                    {signedIn
                        ? <>
                            <BlogNavItem/>
                            <NavItem>
                                <NavLink
                                    id="lesson-editor-link"
                                    tag={ReactRouter.NavLink}
                                    to={urls.lesson.create}
                                    className="text-dark btn btn-link nav-link">
                                    Lesson Designer
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    id="article-designer-link"
                                    tag={ReactRouter.NavLink}
                                    to={urls.articles.create}
                                    className="text-dark btn btn-link nav-link">
                                    Article Designer
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <button id="sign-out-btn" onClick={signOut}
                                        className="text-dark btn btn-link nav-link mx-auto">
                                    Sign Out
                                </button>
                            </NavItem>
                        </>
                        : <>
                            <NavItem>
                                <NavLink id="sign-in-nav"
                                         tag={ReactRouter.NavLink}
                                         className="text-dark btn btn-link"
                                         to={urls.account.signin}>Sign In</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    id="register-nav"
                                    tag={ReactRouter.NavLink}
                                    className="text-dark btn btn-link nav-link"
                                    to={urls.account.register}
                                >Register</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    id="about-nav"
                                    tag={ReactRouter.NavLink}
                                    className="text-dark btn btn-link nav-link"
                                    to={urls.about}
                                >About</NavLink>
                            </NavItem>
                            <BlogNavItem/>
                        </>
                    }
                </ul>
            </Collapse>
        </Navbar>
    </header>
}

function BlogNavItem() {
    return <NavItem>
        <a
            id="blog-nav"
            className="text-dark btn btn-link nav-link"
            href="https://articles.mistermandarin.com">Blog</a>
    </NavItem>
}
