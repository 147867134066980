import * as React from 'react';
import {SyntheticEvent, useRef, useState} from 'react';
import {Navigate, useParams} from 'react-router';
import {postJson} from "../helpers/web";
import {getErrorIfExists, getRequiredEmailForm, getRequiredPassword, LoaderOverlay} from "./CommonSubComponents";
import {useDispatch} from "react-redux";
import {EmailConfirmationViewModel} from "../viewModels/EmailConfirmationViewModel";
import {throwIfMissing} from "../helpers/safetyChecks";
import {ResendCodeViewModel} from "../viewModels/ResendCodeViewModel";
import {urls} from "../urls";
import {useNavigate} from "react-router-dom";
import {setUserProfile, useAppSelector} from "../store";

export const EmailConfirmationForm = ({passwordFromRegistration}: { passwordFromRegistration?: string }) => {
    const navigate = useNavigate();
    const emailFromRegistration = useAppSelector(a => a.account.email);
    const emailConfirmed = useAppSelector(a => a.account.emailConfirmed);
    const signedIn = useAppSelector(a => a.account.signedIn);
    const email = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);
    const {code} = useParams<{ code: string }>();
    const [confirmationCode, setConfirmationCode] = useState<string>(code || '');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const desiredSubscriptionPlan = useAppSelector(a => a.account.desiredSubscriptionPlan);
    const getEmailToSendToServer = () => emailFromRegistration ?? throwIfMissing(email.current).value;
    const getPasswordToSendToServer = () => passwordFromRegistration ?? throwIfMissing(password.current).value;
    const dispatch = useDispatch();

    const postConfirmation = async (event: SyntheticEvent) => {
        event.preventDefault();
        event.stopPropagation();

        setLoading(true);

        const data: EmailConfirmationViewModel = {
            email: getEmailToSendToServer(),
            password: getPasswordToSendToServer(),
            code: throwIfMissing(confirmationCode)
        };


        try {
            const result = await postJson(urls.api.account.confirm, data);
            if (result.response.ok) {
                navigate(urls.dashboard);
                dispatch(setUserProfile({json: result.webRequestResult.successData}));
                setErrorMessage(undefined);
            } else {
                setErrorMessage(result.webRequestResult.errorMessage);

            }
        } catch (error) {
            console.error(error)
        }

        setLoading(false);
    };

    const postResendRequest = async () => {
        setLoading(true)
        const result = await postJson<ResendCodeViewModel>(urls.api.account.resendConfirmationCode, {email: getEmailToSendToServer()});
        setLoading(false);
        setErrorMessage(result.webRequestResult.errorMessage);
    };


    const emailConfirmationForm =
        <div className="card mt-5 p-3 mb-5 box-shadow">
            <div className="card-header text-center">
                <h2 id="email-confirmation-header" className="mb-0 mt-2">Confirm Your Email</h2>
            </div>
            <form onSubmit={postConfirmation} className="card-body">
                {getErrorIfExists(errorMessage)}
                <LoaderOverlay loading={loading} />
                <fieldset className="row gy-3" disabled={loading}>
                    {!emailFromRegistration ?
                        <div className="col-12">
                            {getRequiredEmailForm(email)}
                        </div>
                        : null}
                    {!passwordFromRegistration ?
                        <div className="col-12">
                            {getRequiredPassword(password)}
                        </div>
                        : null}
                    <div className="col-12">
                        {/*Make this work for users who didn't confirm their email during registration*/}
                        <label htmlFor="email">Email Confirmation Code</label>
                        <div className="input-group">
                            <input
                                name="confirmationCode"
                                placeholder="Enter your confirmation code"
                                type="text"
                                required={true}
                                onChange={(element) => {
                                    setConfirmationCode(element.currentTarget.value)
                                }}
                                value={confirmationCode}
                                className="form-control"/>
                            <button className="btn btn-outline-secondary" onClick={postResendRequest}
                                    type="button">Resend
                            </button>
                        </div>
                    </div>

                    <div className="col-12">
                        <div className="d-grid g-0">
                            {desiredSubscriptionPlan !== undefined
                                ? <button className="btn btn-primary">Start Free Trial</button>
                                : <button id="confirm-email-button" className="btn btn-primary">Confirm Email</button>
                            }
                        </div>
                    </div>
                </fieldset>
            </form>
        </div>;

    if (!emailConfirmed || !!desiredSubscriptionPlan) {
        return emailConfirmationForm
    }

    if (signedIn) {
        return <Navigate to={urls.dashboard}/>;
    } else {
        return <Navigate to={urls.account.signin}/>;
    }
}
