import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {getHeightOfWindow, getWidthOfWindow} from "../helpers/utils";
import CharacterAudioComponent from "./CharacterAudioComponent";
import {QuizWord} from "./Quiz";
import {softAssertNever} from "../helpers/safetyChecks";
import Translations from "./Translations";
import CharacterDisplay from "./CharacterDisplay";
import {QuizType} from "../generated/graphql/graphql-zeus";
import Pinyin from "./Pinyin";

enum HandwritingQuizState {
    Starting = "Starting",
    InProgress = "InProgress",
    Complete = "Completed",
    Stopped = "Stopped",
}

const allowedTransitions = {
    [HandwritingQuizState.Starting]: [HandwritingQuizState.InProgress],
    [HandwritingQuizState.InProgress]: [HandwritingQuizState.Complete],
    [HandwritingQuizState.Stopped]: [HandwritingQuizState.Starting],
    [HandwritingQuizState.Complete]: [HandwritingQuizState.Stopped],
    [HandwritingQuizState.Complete]: [HandwritingQuizState.Starting],
}

function enforceTransition(currentState: HandwritingQuizState, targetState: HandwritingQuizState) {
    if (!allowedTransitions[currentState].some(x => x == targetState)) {
        console.warn("Invalid transition from: ", currentState, targetState);
    }
}

export default (props: { word: QuizWord, onCompleted: (completedWord: QuizWord, answerWasCorrect: boolean) => void }) => {
    const currentWord: QuizWord | undefined = props.word;
    const [attempt, setAttempt] = useState(0);
    const [state, _setState] = useState<HandwritingQuizState>(HandwritingQuizState.Stopped)
    const [repeatWord, setRepeatWord] = useState<boolean>(false);
    const [hadMistakes, setHadMistakes] = useState(false)
    const [reanimationRequestedCount, setReanimationRequestedCount] = useState<number>(0);
    const quizSideLength = Math.min(500, getWidthOfWindow() - 80, getHeightOfWindow() * 0.65);
    const [charactersCompleted, setCharactersCompleted] = useState<number>(0);
    // Correct for stale ref in oncompleted method below
    const charactersCompletedRef = useRef<number>(charactersCompleted);
    charactersCompletedRef.current = charactersCompleted;
    const totalCharacters = currentWord.chinese.length;

    function transitionState(targetState: HandwritingQuizState) {
        enforceTransition(state, targetState);
        _setState(targetState);
        console.log(targetState)
    }

    useEffect(() => {
        transitionState(HandwritingQuizState.Starting);
    }, [currentWord])

    useEffect(() => {
        switch (state) {
            case HandwritingQuizState.Starting:
                setAttempt(attempt + 1)
                setRepeatWord(false);
                setHadMistakes(false);
                setReanimationRequestedCount(0);
                setCharactersCompleted(0)
                transitionState(HandwritingQuizState.InProgress)
                break
            case HandwritingQuizState.InProgress:
                if (charactersCompleted == totalCharacters) {
                    transitionState(HandwritingQuizState.Complete);
                }
                break;
            case HandwritingQuizState.Stopped:
                break;
            case HandwritingQuizState.Complete:
                if (reanimationRequestedCount || hadMistakes)
                {
                    transitionState(HandwritingQuizState.Starting)
                }
                else {
                    transitionState(HandwritingQuizState.Stopped)
                    props.onCompleted(currentWord, reanimationRequestedCount == 0 && !repeatWord)
                }
                break;
            default:
                softAssertNever(state, true);
        }
    }, [state, charactersCompleted])

    async function updateWriterOnComplete(data: { totalMistakes: number; character: string }) {
        if (data.totalMistakes > 5) {
            setRepeatWord(true);
        }

        setHadMistakes(data.totalMistakes > 0 || hadMistakes);
        setCharactersCompleted(charactersCompletedRef.current + 1)
    }

    async function reanimateCharacters() {
        if (currentWord) {
            setReanimationRequestedCount(reanimationRequestedCount + 1);
            setCharactersCompleted(0)
        }
    }


    return (<>
            <div className="d-flex justify-content-center">
                <div style={{maxWidth: 750}} className="text-center text-large-lg">
                    <Translations word={currentWord} style={'inline'}/>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <div style={{maxWidth: 750}} className="text-center text-large-lg">
                    {attempt > 1 ? <div className="text-end text-success">Attempt {attempt}</div> : null}
                </div>
            </div>
            {/*Setting a key here is a bit of a hack. It forces the entire character display to rerender so that the animation is replayed*/}
            <div className="text-center" key={`${reanimationRequestedCount}-${attempt}`}>
                <CharacterDisplay word={currentWord}
                                  animationOptions={{
                                      animate: currentWord.progress <= 2 || reanimationRequestedCount > 0,
                                      stopAfter: 1,
                                      animationSpeed: 1 + Math.min((currentWord.strokeCount / 10), 1)
                                  }}
                                  addShadow={true}
                                  sideLength={quizSideLength}
                                  showTianZeGe={true}
                                  context={QuizType.HANDWRITING}
                                  showCharacter={false}
                                  showOutline={currentWord.progress < 4 || reanimationRequestedCount > 0}
                                  quizOptions={{
                                      onComplete: updateWriterOnComplete,
                                      onCorrectStroke: () => {
                                      },
                                      onMistake: async (data) => {
                                          if (data.totalMistakes > 5) {
                                              setRepeatWord(true);
                                          }
                                      }
                                  }}
                />
            </div>
            <div className="d-flex align-items-center">
                <div className="flex-grow-1">
                    <i className="icon-brush fs-3rem clickable pr-2 subtle-shadow-text"
                       title="Reanimate character(s)"
                       onClick={reanimateCharacters}/>
                </div>
                <h3 className="flex-grow-1">
                    <Pinyin pinyin={currentWord.pinyin}/>
                </h3>
                <CharacterAudioComponent pinyin={currentWord.pinyin} showPlayButton={true} className="fs-3rem"
                                         autoPlay/>
            </div>
        </>
    )
}

