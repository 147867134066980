import React, {ErrorInfo, ReactNode} from "react";
import {GraphQLError} from "../generated/graphql/graphql-zeus";
import {StandardLayout} from "./Layout";
import {urls} from "../urls";

interface ErrorProps {
    children: ReactNode
}

interface ErrorState {
    hasError: boolean;
}

export class ErrorBoundary extends React.Component<ErrorProps, ErrorState> {
    constructor(props: ErrorProps) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error: GraphQLError | any) {
        return {hasError: true};
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    }

    render() {
        if (this.state.hasError) {      // You can render any custom fallback UI      return <h1>Something went wrong.</h1>;    }
            return <StandardLayout>
                <div className="row mt-5 full-height justify-content-center">
                    <div className="col-auto mt-5">
                        <div className="card">
                            <div className="card-header text-center">
                                <h2 className="mb-0 mt-2">Oops!</h2>
                            </div>
                            <div className="card-body text-center">
                                <p>Something unexpected happened!</p>
                                <p className="d-grid g-0">
                                    <a href={urls.home} className="btn btn-primary btn-lg">Return to home page</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </StandardLayout>
        }

        return this.props.children;
    }
}