export function throwIfMissing<T>(value: T | undefined | null): T {
    if (value === null || value === undefined) {
        throw new Error("Expected value not found");
    } else {
        return value;
    }
}

export function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x);
}

// Used to assert in situations where we want compile time assertions
// but not runtime assertions
export function softAssertNever(x: never, warn: boolean = false) {
    if (warn) {
        console.warn("Unrecognised value", x);
    }
}

export function nameof<T>(key: keyof T, instance?: T): keyof T {
    return key;
}