import * as React from "react";
import {useRef, useState} from "react";
import {urls} from "../urls";

export default function CharacterAudioComponent(props: { pinyin: string, showPlayButton: boolean, autoPlay: boolean, className?: string }) {
    const [timesAudioPlayed, setTimesAudioPlayed] = useState<number>(0);
    const femaleAudio = useRef<HTMLAudioElement>(null);
    const maleAudio = useRef<HTMLAudioElement>(null);
    const pinyin = props.pinyin;

    const playAudio = async () => {
        try {
            if (timesAudioPlayed % 2 === 0) {
                await femaleAudio.current?.play();
            } else {
                await maleAudio.current?.play();
            }

            setTimesAudioPlayed(timesAudioPlayed + 1);
        } catch (error) {
            console.error(error);
        }
    }

    return (<span>
        <audio ref={femaleAudio} src={urls.audio.getAudioTagUrlFemale(pinyin)}
               autoPlay={timesAudioPlayed % 2 !== 0 && props.autoPlay}/>
        <audio ref={maleAudio} src={urls.audio.getAudioTagUrlMale(pinyin)}
               autoPlay={timesAudioPlayed % 2 === 0 && props.autoPlay}/>
        {props.showPlayButton ?
            <i className={`clickable pr-2 subtle-shadow-text icon-volume-up  ${props.className}`} title="Play audio" onClick={playAudio}/>
            : null}
    </span>)
}